<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="6">
        <customer-user-list-cart :customer_id="customer_id" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomerUserListCart from "@/views/custom_app/components/customer/CustomerUserListCart.vue"
import {getUserData} from "@/auth/utils";
import {BCol, BRow} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    CustomerUserListCart,
  },
  data() {
    return {
      customer_id: getUserData().company_id,
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
